import React from "react";
import ReactPlayer from "react-player";
import ProposalVideo from "../images/ProposalVideo.mp4";

export default function OurStory() {
  return (

    <div className='player-wrapper'>
    <ReactPlayer
    config={{
      file: {
        forceVideo: true
      }
    }}
    controls
      className='react-player'
      url={ProposalVideo}
      width='100%'
      height='100%'
    />
  </div>
  );
}
