import React from "react";
import Carousel from "react-bootstrap/Carousel";
import PhotoJson from "../Photos.json";
import { Col, Container, Row } from "react-bootstrap";
import Image from 'react-bootstrap/Image';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from "react-slideshow-image";

const responsiveSettings = [
  {
      breakpoint: 800,
      settings: {
          slidesToShow: 3,
          slidesToScroll: 3
      }
  },
  {
      breakpoint: 500,
      settings: {
          slidesToShow: 2,
          slidesToScroll: 2
      }
  }
];

export default function Photos() {
  const folderPath = "../images/Photos/";

  return (

    <div>
    <Slide slidesToScroll={1} slidesToShow={1} indicators={true} responsive={responsiveSettings}>
    {PhotoJson.map((image) => (
                <Image fluid thumbnail alt={image.Path} src={image.Path} />
            ))}
    </Slide>
</div>

   /* <Container>
      <Row>
        <Col xs lg="2"></Col>
        <Col md="auto">
          <Carousel fade>
            {PhotoJson.map((image) => (
              <Carousel.Item>
                <Image fluid thumbnail height={750} width={750} alt={image.Path} src={image.Path} />
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
        <Col xs lg="2"></Col>
      </Row>
    </Container> */
  );
}
