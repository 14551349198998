import React from "react";
import MapCard from "../components/MapCard/MapCard";

export default function VenueAndHotel() {
  return (
    <div className="card-group">
      <MapCard
      title="Venue"
        mapName1="College Park Women's Club"
        mapName2="Camellia Hall"
        mapAddress="3413 N. Main St., College Park, GA 30337"
        src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d13285.184192966752!2d-84.45105293307864!3d33.64949018535292!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x88f4fd2fe1035901%3A0x4117a3ef1892b048!2sHartsfield-Jackson%20Atlanta%20International%20Airport%2C%20North%20Terminal%20Parkway%20Suite%204000%2C%20Atlanta%2C%20GA!3m2!1d33.6361631!2d-84.4293751!4m5!1s0x88f4e2afb9a4a75f%3A0x396d3cd9228f705!2sCollege%20Park%20Woman%E2%80%99s%20Club%20Camellia%20Hall%2C%203413%20Main%20St%2C%20College%20Park%2C%20GA%2030337!3m2!1d33.6624659!2d-84.446856!5e0!3m2!1sen!2sus!4v1711265978995!5m2!1sen!2sus"
        linkURL="https://www.collegeparkwomansclub.org"
        linkName="https://www.collegeparkwomansclub.org"
      ></MapCard>

      <MapCard
      title="Hotel Reservation "
        mapName2="Discounted Rate"
        mapName1="Holiday Inn Express College Park"
        mapAddress="1419 Virginia Avenue, College Park, GA"
        src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d13285.263393554907!2d-84.45105293307896!3d33.64897703546259!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x88f4fd2fe1035901%3A0x4117a3ef1892b048!2sHartsfield-Jackson%20Atlanta%20International%20Airport%2C%20North%20Terminal%20Parkway%20Suite%204000%2C%20Atlanta%2C%20GA!3m2!1d33.6361631!2d-84.4293751!4m5!1s0x88f4fd4d86caaa05%3A0x11fab975be13c468!2s1419%20Virginia%20Ave%2C%20College%20Park%2C%20GA%2030337!3m2!1d33.6600319!2d-84.4354267!5e0!3m2!1sen!2sus!4v1711337126857!5m2!1sen!2sus"
        linkURL="https://www.ihg.com/holidayinnexpress/hotels/us/en/find-hotels/select-roomrate?fromRedirect=true&qSrt=sBR&qSlH=atlpk&qRms=1&qAdlt=1&qChld=0&qCiD=14&qCiMy=052024&qCoD=16&qCoMy=052024&qGrpCd=HTW&setPMCookies=true&qSHBrC=EX&qDest=1419%20Virginia%20Avenue,%20College%20Park,%20GA,%20US&qpMn=0&srb_u=1&qChAge=&qRmFltr="
        linkName="Reservation Info"
      ></MapCard>
    </div>
  );
}
