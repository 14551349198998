import React from "react";
import MainNavbar from "./components/Navbar/Navbar.js";
import Home from "./pages/Home";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Links from "./pages/Links";
import VenueAndHotel from "./pages/VenueAndHotel";
import Photos from "./pages/Photos";
import MainFooter from "./components/Footer/Footer.js";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import OurStory from "./pages/OurStory.js";
import DocumentTitle from "react-document-title";

function App() {
  return (
    <DocumentTitle title="Will and Marvelous's Wedding">
      <Router>
        <MainNavbar />
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/OurStory" element={<OurStory/>} />
          <Route path="/VenueAndHotel" element={<VenueAndHotel/>} />
          <Route path="/Photos" element={<Photos/>} />
          <Route path="/Links" element={<Links/>} />
        </Routes>
        <MainFooter/>
      </Router>
    </DocumentTitle>
  );
}


export default App;