import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
import "./Navbar.css";

function MainNavbar() {
  return (
    <div className='mainNavbar  sticky-top bg-body-tertiary'>
    <Navbar collapseOnSelect expand="lg" className='navbar-light' data-bs-theme="light" >
      <Container>
        <Navbar.Brand href="/">The Talley Family Wedding</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          <Nav.Link href="#/OurStory">Our Story</Nav.Link>
            <Nav.Link href="#/photos">Photos</Nav.Link>
            <Nav.Link href="#/VenueAndHotel">Venue and Hotel Information</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="#/Links">Links</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar></div>
  );
}

export default MainNavbar;