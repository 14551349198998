import React from "react";
import { Link } from "react-router-dom";
import "./MapCard.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

function MapCard(props) {
  return (
    <Card className="mapContainer"  variant="flush">
      <Card.Header>
        <span className="Aramis fs-1 fw-bold">{props.title}</span>
      </Card.Header>
      <Card.Body>
        <Card.Title>
          <span className=" fs-3 fw-bold">{props.mapName1} </span>
        </Card.Title>
        <Card.Subtitle>
          <span className=" fs-3 fw-bold">{props.mapName2}</span>
        </Card.Subtitle>
        <Card.Text>
          <span className=" fs-3 fw-bold">{props.mapAddress}</span>
        </Card.Text>
        <Card.Text>
          <iframe
            class="embed-responsive-item"
            title="Distance From Hartsville-Jackson Airport"
            src={props.src}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          />{" "}
        </Card.Text>
        <Card.Link>
          <a href={props.linkURL}>{props.linkName}</a>
        </Card.Link>
      </Card.Body>
    </Card>
  );
}

export default MapCard;
