import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function MainFooter() {
  return (
    <footer className='mainFooter footer mt-auto fixed-bottom footer-container'>
    <div>
        <section className='footer-link'>@2024 William Talley</section>
        <section className='footer-link'><a href="https://www.flaticon.com/free-icons/love-and-romance" title="love and romance icons">Love and romance icons created by Iconfromus - Flaticon</a></section>
    </div></footer>
  );
}

export default MainFooter;