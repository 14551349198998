import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import "./Links.css";

export default function Links() {
  return (
    <ListGroup className='linkList ' variant='flush'>
      <ListGroup.Item className='text-xl-center font-weight-bold'><a href='http://youtube.com/@screwfacecapone'>Groom's Youtube</a></ListGroup.Item>
      <ListGroup.Item className='text-xl-center font-weight-bold'><a href='https://www.collegeparkwomansclub.org/'>College Park Women's Association</a></ListGroup.Item>
      <ListGroup.Item className='text-xl-center font-weight-bold'><a href='https://www.amazon.com/wedding/registry/LPT5736D4I78'>Amazon Gift Registry</a></ListGroup.Item>
    </ListGroup>
  );
}